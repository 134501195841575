<template>
        <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
            <div class="container mx-auto">
                <div class="grid">
                    <div class="col-span-12">
                        <div class="text-center text-white">
                            <h3 class="mb-4 text-[26px]">Hiring Companies</h3>
                            <div class="page-next">
                                <nav class="inline-block" aria-label="breadcrumb text-center">
                                    <p>Search {{ companyResultCount }} leading web3 industry companies hiring on TheJobTap, from around the world</p>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
            <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
        </section>

<!-- Start grid -->
    <section class="py-16">
        <div class="container mx-auto">

          <div v-if="companiesLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
            <div v-else-if="companies != ''" class="grid items-center grid-cols-12 mb-4">
                <div class="col-span-12 lg:col-span-8">
                    <div class="mb-lg-0">
                        <h6 class="text-gray-900 text-16 dark:text-gray-50"> Loaded {{ companyShowingCount  }} of {{ companyResultCount }} results </h6>
                    </div>
                </div><!--end col-->

                <!--<div class="col-span-12 lg:col-span-4">
                    <div class="candidate-list-widgets">
                        <div class="grid items-center grid-cols-12 gap-3">
                            <div class="col-span-12 lg:col-span-6">
                                <div class="selection-widget">
                                    <select class="form-select" data-trigger name="choices-single-filter-orderby" id="choices-single-filter-orderby" aria-label="Default select example">
                                        <option value="df">Default</option>
                                        <option value="ne">Newest</option>
                                        <option value="od">Oldest</option>
                                        <option value="rd">Random</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-6">
                                <div class="selection-widget">
                                    <select class="form-select" data-trigger name="choices-candidate-page" id="choices-candidate-page" aria-label="Default select example">
                                        <option value="df">All</option>
                                        <option value="ne">8 per Page</option>
                                        <option value="ne">12 per Page</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
            <div class="mt-8">
                <div class="grid grid-cols-12 gap-8">
                    <div v-bind:key="company.Name" v-for="company in companies" class="col-span-12 lg:col-span-3">
                        <div class="relative px-6 py-6 border rounded-md border-gray-100/50 dark:border-neutral-600">
                            <img style="height: 70px;" :src=company.ImageLogoUrl alt="" class="mx-auto">
                            <div class="mt-5 text-center">
                                <a href="javascript:void(0)" v-on:click="companyClick(company.Name)"><h6 class="mb-3 text-lg text-gray-900 dark:text-white">{{ company.Name }}</h6></a>
                                <p class="mb-6 text-gray-500 dark:text-gray-300">{{ company.EmployeeThreshold }} employees</p>
                                <a href="javascript:void(0)" v-on:click="companyClick(company.Name)" class="text-white border-transparent btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 focus:ring focus:ring-custom-500/30">
                                  {{ company.ActiveJobListingCount }} Opening <span v-if="company.ActiveJobListingCount === 1">Job</span><span v-else>Jobs</span>
                                </a>
                            </div>
                            <!-- <div class="absolute px-2 text-white bg-violet-500 top-3 ltr:right-0 rtl:left-0">
                                <span class="relative text-xs font-medium uppercase">4.9 <i class="mdi mdi-star-outline"></i></span>
                                <div class="after:contant[] ltr:after:border-r-[11px] rtl:after:border-l-[11px] after:absolute after:border-b-[11px] ltr:after:-left-[11px] rtl:after:-right-[11px] after:top-0 after:border-t-[11px] after:border-t-transparent after:border-violet-500 after:border-b-transparent"></div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12">
                <div class="col-span-12">
                  <!-- pages -->
                </div>
                <!--end col-->
            </div>
        </div>
    </section>
</template>
<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { FeedbackNotification },
  data() {
    return {
      companies : [],
      companyResultCount : 0,
      companyShowingCount : 0,
      companiesLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.handleSearchCompanies()
 },
  mounted() {
    document.title = 'Hiring Companies - TheJobTap.com'
  },
  methods: {
    async handleSearchCompanies() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.search();
          this.companies = result.Results;
          this.companyShowingCount = result.ResultCount;
          this.companyResultCount = result.Results.length; 
        })
      );
      this.companiesLoading = false;
      if (this.ResultCount === 0)
      {
        this.notification = { text : "No company results found", level : "notification" };        
      }
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
    async companyClick(companyName) {
      this.$router.push('/hiring-companies/' + companyName);
    },
  },
};
</script>