<template>
<div class="page-content"  data-test-key="advertise-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Hire with us</h3>
                        <div class="page-next">
                          <p>The crypto industry's favorite job board - Streamline Your Hiring Process and Reach Top Talent</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
  </section>

    <section class="py-20">
        <div class="container mx-auto">
            <div class="grid grid-cols-1">
                <div class="relative">

<div class="relative mt-4">
    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Hire with Us: Streamline Your Hiring Process and Reach Top Talent</h5>
    <p class="text-gray-500 about-list dark:text-gray-300">
    At TheJobTap, we help companies like yours connect with the right candidates—quickly, easily, and efficiently. With our seamless auto-job listing service, you can automatically pull in your job postings and have them listed on our platform without the hassle of manual entry. All it takes is a simple subscription, and your jobs will be visible to a broad, qualified pool of job seekers.
    </p>
</div>

<div class="relative mt-4">
    <h6 class="mb-4 text-gray-900 dark:text-gray-50">Why Choose TheJobTap for Your Hiring Needs?</h6>
    <p class="text-gray-500 about-list dark:text-gray-300">
    
    
    
    </p>
    
    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                            Automated Job Listings: Our auto-job listing product effortlessly pulls your job postings directly from your site and displays them on our platform in real time. No need to re-enter or manually update job listings—just subscribe and let our system do the work.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Reach a Broader Audience: Get access to thousands of job seekers who are actively searching for their next opportunity. Our platform connects you with top talent across multiple industries.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Save Time & Resources: Focus on what matters most—finding the right candidate—while we handle the posting. Our automated system ensures that your job openings are always up-to-date and ready to be discovered.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Cost-Effective Solution: With a simple monthly subscription, you’ll have unlimited access to post your job listings and find the talent you need without hidden fees or extra costs.
                        </li>
                    </ul>

</div>

<div class="relative mt-4">
    <h6 class="mb-4 text-gray-900 dark:text-gray-50">How It Works:</h6>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Subscribe: Choose the monthly plan that fits your hiring needs and subscribe to get started.
    </p>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Connect Your Job Listings: Our auto-job listing tool pulls your job postings directly from your website—automatically syncing your open roles with our platform.
    </p>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Reach Qualified Candidates: As your job listings are posted, they'll be shown to relevant candidates across multiple channels, giving you maximum visibility.
    </p>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Hire Smarter: With our automated system, you’ll have more time to focus on interviewing and selecting the best candidates.
    </p>
</div>

<div class="relative mt-4">
    <h6 class="mb-4 text-gray-900 dark:text-gray-50">The Benefits of Partnering with Us:</h6>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Seamless Integration: Set up your job feeds with ease and let our system do the rest. You’ll never need to worry about manual updates.
    </p>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Diverse Candidate Pool: Our platform attracts a wide range of job seekers, from entry-level to experienced professionals, across various industries.
    </p>
    <p class="text-gray-500 about-list dark:text-gray-300">
    24/7 Job Visibility: Your listings will be available to job seekers 24/7, meaning you can attract talent even outside of traditional working hours.
    </p>
</div>

<div class="relative mt-4">
    <h6 class="mb-4 text-gray-900 dark:text-gray-50">Ready to Get Started?</h6>
    <p class="text-gray-500 about-list dark:text-gray-300">
    Simplify your hiring process and reach the best candidates with [Your Site Name]. Subscribe today and start posting your job listings with ease.
    [Subscription Plans / Sign-Up Button]</p>
</div>



    
 
                <div class="relative mt-4">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Why hire with TheJobTap?</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                          Hiring the right talent
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Deployed to web,
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Take advantage of our job distribution network, google jobs, emails, advertisements, telegram
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

    
                <!-- start process -->
                <section class="dark:bg-neutral-800">
                        <div class="container mx-auto">
                            <div class="nav-tabs round-pill">
                                <div class="grid items-center grid-cols-12 gap-5">
                                    <div class="col-span-12 lg:col-span-6">
                                        <h3 class="mb-3 text-3xl text-gray-900 dark:text-gray-50">How It Works</h3>
                                        <p class="text-gray-500 dark:text-gray-300">Post a job to tell us about your project. We'll quickly match you with the
                                            right freelancers.</p>

                                        <div class="mt-5">
                                            <ul class="text-gray-700 nav">
                                                <li class="w-full mb-[22px]">
                                                    <a href="javascript:void(0);" data-tw-toggle="tab" data-tw-target="v-pills-home-tab" class="relative inline-block w-full p-2 active group/active" aria-current="page">
                                                    <div class="after:content-[''] after:h-[65px] after:border after:border-dashed after:border-gray-100 after:absolute ltr:after:left-7 rtl:after:right-7 after:-bottom-5 after:group-[.active]:bg-violet-300 hidden md:block"></div>
                                                        <div class="flex">
                                                            <div class="shrink-0 h-10 w-10 rounded-full text-center bg-gray-500/20 group-[.active]:group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:group-[.active]:bg-sky-500 group-data-[theme-color=red]:group-[.active]:bg-red-500 group-data-[theme-color=green]:group-[.active]:bg-green-500 group-data-[theme-color=pink]:group-[.active]:bg-pink-500 group-data-[theme-color=blue]:group-[.active]:bg-blue-500">
                                                                <span class="text-gray-900 group-[.active]:text-white text-16 leading-[2.5] dark:text-gray-50">1</span>
                                                            </div>
                                                            <div class="grow ltr:ml-4 rtl:mr-4">
                                                                <h5 class="fs-18 text-gray-900 group-data-[theme-color=violet]:group-[.active]:text-violet-500 group-data-[theme-color=sky]:group-[.active]:text-sky-500 dark:text-gray-50">Register an account</h5>
                                                                <p class="mt-1 mb-2 text-gray-500 dark:text-gray-300">
                                                                    Due to its widespread use as filler text for layouts, non-readability is of great importance.</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="w-full mb-[22px]">
                                                    <a href="javascript:void(0);" data-tw-toggle="tab" data-tw-target="v-pills-profile" class="relative inline-block w-full p-2 group" aria-current="page">
                                                    <div class="after:content-[''] after:h-[65px] after:border after:border-dashed after:border-gray-100 after:absolute ltr:after:left-7 rtl:after:right-7 after:-bottom-5 after:group-[.active]:bg-violet-300 hidden md:block"></div>
                                                        <div class="flex">
                                                            <div class="shrink-0 h-10 w-10 rounded-full text-center bg-gray-500/20 group-[.active]:bg-violet-500">
                                                                <span class="text-gray-900 group-[.active]:text-white text-16 leading-[2.5] dark:text-gray-50">2</span>
                                                            </div>
                                                            <div class="grow ltr:ml-4 rtl:mr-4">
                                                                <h5 class="fs-18 text-gray-900 group-[.active]:text-violet-500 dark:text-gray-50">Find your job</h5>
                                                                <p class="mt-1 mb-2 text-gray-500">There are many variations of passages of avaibookmark-label, but the majority
                                                                alteration in some form.</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="w-full mb-[22px]">
                                                    <a href="javascript:void(0);" data-tw-toggle="tab" data-tw-target="v-pills-messages" class="relative inline-block w-full p-2 group" aria-current="page">
                                                        <div class="flex">
                                                            <div class="shrink-0 h-10 w-10 rounded-full text-center bg-gray-500/20 group-[.active]:bg-violet-500">
                                                                <span class="text-gray-900 group-[.active]:text-white text-16 leading-[2.5] dark:text-gray-50">3</span>
                                                            </div>
                                                            <div class="grow ltr:ml-4 rtl:mr-4">
                                                                <h5 class="fs-18 text-gray-900 group-[.active]:text-violet-500 dark:text-gray-50">Apply for job</h5>
                                                                <p class="mt-1 mb-2 text-gray-500">It is a long established fact that a reader will be distracted by the
                                                                readable content of a page.</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                    <div class="col-span-12 lg:col-span-6">
                                        <div class="tab-content">
                                            <div class="block tab-pane" id="v-pills-home-tab">
                                                <img src="@/assets/images/process-01.png" alt="" class="max-w-full">
                                            </div>
                                            <div class="hidden tab-pane" id="v-pills-profile">
                                                <img src="@/assets/images/process-02.png" alt="" class="max-w-full">
                                            </div>
                                            <div class="hidden tab-pane" id="v-pills-messages">
                                                <img src="@/assets/images/process-03.png" alt="" class="max-w-full">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <!-- end process -->
</div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Hire with us - TheJobTap.com'
  }
};
</script>