<template>

<div class="page-content">
                <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
                    <div class="container mx-auto">
                        <div class="grid">
                            <div class="col-span-12">
                                <div class="text-center text-white">
                                    <h3 class="mb-4 text-[26px]">Web3 job skills</h3>
                                    <div class="page-next">
                                     <p>Find your perfect job by searching for specific skills! Discover opportunities tailored to your expertise in Web3 and beyond.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
                    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
                </section>

                    <section class="py-20" v-if="jobListingCategoriesTotal != null">
                        <div class="container mx-auto">
                            <div class="grid grid-cols-12 gap-y-10 lg:gap-10">
                                <div class="col-span-12 lg:col-span-6 lg:col-start-4">
                                   <div class="mb-5 text-center">
                                        <!-- <p class="inline px-2 py-1 text-sm font-medium text-white bg-yellow-500 rounded">Jobs Live Today</p> -->
                                        <h4 class="mt-2 text-gray-900 text-22 dark:text-white">Browse Web3 jobs over {{ this.jobListingCategoriesTotal }} skills</h4>
                                        <p class="mt-2 text-gray-500 dark:text-gray-300">Find your perfect job by searching for specific skills! Discover opportunities tailored to your expertise in Web3 and beyond. Quickly browse job listings that match your skill set and take the next step in your career today!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-12 gap-y-5 md:gap-8">
                                <div class="col-span-12 md:col-span-6 xl:col-span-4">
                                    <div class="mt-10 rounded bg-gray-50 dark:bg-neutral-700">
                                        <div class="p-6">
                                            <ul class="space-y-4">
                                                <li class="px-4 py-2 bg-white rounded dark:bg-neutral-600" 
                                                    v-bind:key="jobListingCategory.Name" 
                                                    v-for="jobListingCategory in jobListingCategories.slice(0, jobListingCategoriesTotal / 3)">
                                                    <router-link :to="/web3-jobs/ + jobListingCategory.Name + '/'" class="text-gray-900 dark:text-white">{{ jobListingCategory.Name }}
                                                        <span class="px-2 py-1 rounded bg-sky-500/20 text-11 text-sky-500 ltr:float-right rtl:float-left">{{ jobListingCategory.JobListingCount }}</span>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-6 xl:col-span-4">
                                    <div class="mt-10 rounded bg-gray-50 dark:bg-neutral-700">
                                        <div class="p-6">
                                            <ul class="space-y-4">
                                                <li 
                                                    class="px-4 py-2 bg-white rounded dark:bg-neutral-600" 
                                                    v-bind:key="jobListingCategory.Name" 
                                                    v-for="jobListingCategory in jobListingCategories.slice(jobListingCategoriesTotal / 3, (jobListingCategoriesTotal / 3) * 2)">
                                                    <router-link :to="/web3-jobs/ + jobListingCategory.Name + '/'" class="text-gray-900 dark:text-white">{{ jobListingCategory.Name }}
                                                        <span class="px-2 py-1 rounded bg-sky-500/20 text-11 text-sky-500 ltr:float-right rtl:float-left">{{ jobListingCategory.JobListingCount }}</span>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-6 xl:col-span-4">
                                    <div class="mt-10 rounded bg-gray-50 dark:bg-neutral-700">
                                        <div class="p-6">
                                            <ul class="space-y-4">
                                                <li class="px-4 py-2 bg-white rounded dark:bg-neutral-600"
                                                    v-bind:key="jobListingCategory.Name" 
                                                    v-for="jobListingCategory in jobListingCategories.slice(jobListingCategoriesTotal / 3 * 2, (jobListingCategoriesTotal / 3) * 3)">
                                                    <router-link :to="/web3-jobs/ + jobListingCategory.Name + '/'" class="text-gray-900 dark:text-white">{{ jobListingCategory.Name }}
                                                        <span class="px-2 py-1 rounded bg-sky-500/20 text-11 text-sky-500 ltr:float-right rtl:float-left">{{ jobListingCategory.JobListingCount }}</span>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

            </div>
</template>

<script>

import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "JobListingSearchCategories",
  components : {
  }, 
  setup () 
  {
  },
  data() {
    return {
      tagSearch: [],
      jobListingCategories: [],
      jobListingCategoriesTotal: null
    };
  },
  mounted() {
      this.getJobListingCategories();
  },
  methods: {
  async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          result.forEach((element) => { 
            this.jobListingCategories.push(element);
          })
          this.jobListingCategoriesTotal = result.length;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
  }
}
</script>