<template>
  <div class="page-content"  data-test-key="terms-and-privacy-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Policies</h3>
                        <div class="page-next">
                            <p>At TheJobTap, we value your privacy and are committed to protecting your personal information.<br/> This page outlines our policies regarding data collection, use, and storage, so you can feel confident using our platform.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>

<!-- Start Privacy-policy -->
    <section class="py-20">
        <div class="container mx-auto">
            <div class="grid grid-cols-1">
                <div class="relative">
                    <h2 class="mb-4 text-gray-900 dark:text-gray-50">Our Policies</h2>

            <p class="mb-4 text-gray-500 dark:text-gray-300">
                At TheJobTap.com, we value your privacy and are committed to protecting your personal information. This page outlines our policies regarding data collection, use, and storage, so you can feel confident using our platform.
            </p>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">1. Email Subscriptions</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    When you subscribe to receive job alerts, we collect only your email address. This is the only personal information required to sign up for notifications about job openings that match your preferences. Your email address will be used exclusively to send you relevant job notifications and updates from TheJobTap.com.
                </p>
                <ul class="pb-2 mb-4 mt-2 space-y-4 text-gray-500 about-list dark:text-gray-300">
                    <li class="ltr:pl-7 rtl:pr-7"><strong>How We Use Your Data:</strong> Your email address is stored securely and is used solely for the purpose of sending you job alerts. We will not share your email address with any third parties without your consent.</li>
                    <li class="ltr:pl-7 rtl:pr-7"><strong>Unsubscribe Anytime:</strong> If you no longer wish to receive notifications, you can easily unsubscribe by clicking the "unsubscribe" link at the bottom of any email or by adjusting your preferences through your subscription settings.</li>
                </ul>
            </div>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">2. Job Applications</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    When you apply for a job listed on our platform, you will be redirected to the company’s own website to complete the application process. TheJobTap.com does not capture or store any data related to your job application.
                </p>
                <ul class="pb-2 mb-4 mt-2 space-y-4 text-gray-500 about-list dark:text-gray-300">
                    <li class="ltr:pl-7 rtl:pr-7">
                        <strong>Data Submission:</strong> Any personal information you provide when applying for a job—such as your resume, cover letter, or any other details—will be directly handled by the hiring company.
                    </li>
                    <li class="ltr:pl-7 rtl:pr-7">
                        <strong>No Data Capture by Us:</strong> We do not collect, store, or process any personal data related to job applications. All applications are submitted through the company's website, and we are not involved in the hiring process.
                    </li>
                </ul>
            </div>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">3. Third-Party Links</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    Our platform may contain links to third-party websites, including the job application pages of companies. Please be aware that once you leave our site, we are not responsible for the privacy practices of those websites. We encourage you to review the privacy policies of any third-party sites you visit.
                </p>
            </div>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">4. Data Security</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    We take data security seriously. The email addresses we collect are stored securely, and we implement appropriate technical and organizational measures to protect your data. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>
            </div>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">5. Changes to Our Policies</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    We may update our policies from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page with the date of the revision.
                </p>
            </div>

            <div class="relative mt-4">
                <h5 class="mb-4 text-gray-900 dark:text-gray-50">6. Contact Us</h5>
                <p class="text-gray-500 dark:text-gray-300">
                    If you have any questions or concerns about our policies or how we handle your information, feel free to contact us at [contact email].
                </p>
             </div>


                </div>
               
            </div>
        </div>
    </section>
<!-- End Privacy-policy -->

</div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Policies - TheJobTap.com'
  }
};
</script>