<template>
  <div class="page-content" data-test-key="email-subscription-page">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Get Notified</h3>
                        <div class="page-next">
                          <p>At TheJobTap, we believe you should be the first to know about job openings that match your skills and interests.
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>
    <section class="py-20">
        <div class="container mx-auto">
            <div class="grid grid-cols-1">
                <div class="relative">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Stay Ahead of the Curve: Get Notified of New Job Opportunities!</h5>
                    <p class="text-gray-500 about-list dark:text-gray-300">
                    At TheJobTap.com, we know how important it is to stay up-to-date with the latest job openings that match your skills and aspirations. With our “Get Notified” feature, you can be the first to hear about new positions as soon as they’re posted—so you never miss an opportunity.
                    </p>
                    <p class="text-gray-500 about-list dark:text-gray-300">
                    We believe you should be the first to know about job openings that match your skills and interests. With our “Get Notified” feature, you can receive instant updates whenever a new job is posted that suits your profile—directly to your email or preferred notification channel.
                    </p>
                </div>

            <div class="relative mt-4">
            <h6 class="mb-4 text-gray-900 dark:text-gray-50">How It Works:</h6>
            <p class="text-gray-500 about-list dark:text-gray-300">
              <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Subscribe to Job Alerts: Simply enter your email and let us know the types of jobs you’re looking for.
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Choose Your Notification Channel: Get notified through your preferred platform—whether it’s email, Telegram, Twitter, or Facebook.
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Get Updates Instantly: Receive real-time notifications as soon as new job listings are available.
                                  </li>
                              </ul>
            </p>
            </div>

          <div class="relative mt-4">
          <h6 class="mb-4 text-gray-900 dark:text-gray-50">Why You’ll Love Getting Notified:</h6>
          <p class="text-gray-500 about-list dark:text-gray-300">
          Instant Updates: Stay in the loop without having to manually search for new openings.
          Stay Ahead of the Competition: Be the first to apply by getting notified as soon as new jobs are posted.
          Customizable Alerts: Choose the job categories, locations, and channels that fit your needs, ensuring that the notifications you receive are tailored just for you.
          </p>
          </div>

          <div class="relative mt-4">
          <p class="text-gray-500 about-list dark:text-gray-300">
          Sign up today and let us bring your next career opportunity to you!

          [Sign Up Button]
                              </p>      
                              <EmailSubscriptionForm 
                                                v-if="!jobListingsLoading"
                                                class="text-center"
                                                :handleResetForm="this.resetEmailForm"
                                                :isRemote=this.search.isRemote
                                                :companyName=null
                                                :tags=this.search.tags
                                                :location=null
                                                :contractType=this.search.contractType
                                                :minSalary=null>
                                                </EmailSubscriptionForm>
                                              </div>

                          <div class="relative mt-4">
                              <h5 class="mb-4 text-gray-900 dark:text-gray-50">Email subscriptions</h5>
                              <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Easy to manage email subscriptions - Show email sub managment
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Can subscribe to any level of the search allowing us to send very relevant jobs
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Manage your email subscription and turn it off with ease!
                                  </li>
                              </ul>
                          </div>
                          <div class="relative mt-4">
                              <h5 class="mb-4 text-gray-900 dark:text-gray-50">Ready to Get Notified? </h5>
                              <p>Sign up for job alerts and choose your preferred notification channel today. Stay ahead and never miss out on your next career opportunity!</p>
                              <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Telegram - Join the telegram channel for new job notifications.
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Twitter - Join us on Twitter for new job and article alerts. Stay updated with the latest industy news.
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    LinkedIn - Connect with us on LinkedIn.
                                  </li>
                                  <li class="ltr:pl-7 rtl:pr-7">
                                    Facebook - Join us on Facebook for new job and article alerts. Stay updated with the latest industy news.
                                  </li>
                              </ul>
                          </div>

            </div>
        </div>
    </section>




    </div>
  </template>
  
  <script>

import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";

  export default{
  name: "GetNotifiedView",
  components : {
    EmailSubscriptionForm
  }, 
    data() {
      return {
        search: { 
        isRemote: false,
        tags: [],
        contractType: null,
      },
      };
    },
    mounted() {
      document.title = 'Get Notified - TheJobTap.com'
    }
  };

  </script>

