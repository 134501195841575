<template>
<div class="page-content">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
    <div class="container mx-auto">
        <div class="grid">
            <div class="col-span-12">
                <div class="text-center text-white">
                    <h3 class="mb-4 text-[26px]">Articles</h3>
                    <div class="page-next">
                      <p>Covering everything from how to land a job in the web3 industry, to useful web3 industry resources and the latest web3 news.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
</section>

                <!-- Start grid -->
<section class="py-16">
  <div class="container mx-auto" id="search">

    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <!-- Articles: All Tags -->
      <ul class="flex flex-wrap gap-2">
            <li :class="{ active: this.search.tags == '' }" class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20">
              <div class="flex items-center">
                <!-- <div class="h-8 w-8 text-center group-data-[theme-color=violet]:bg-violet-500/20 group-data-[theme-color=sky]:bg-sky-500/20 group-data-[theme-color=red]:bg-red-500/20 group-data-[theme-color=green]:bg-green-500/20 group-data-[theme-color=pink]:bg-pink-500/20 group-data-[theme-color=blue]:bg-blue-500/20 leading-[2.4] rounded group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 text-sm font-medium">
                          11
                      </div> -->
                      <a href="javascript:void(0)"
                      class="text-gray-900 dark:text-gray-50" 
                        v-on:click="handleCategoryClick(null)"
                        style= "cursor:pointer;">
                          <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                            All
                          </h6>
                      </a>
                  </div>
            </li>
            <li 
              :class="{ active: search.tags.includes(category) }"
              class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20" 
              v-bind:key="category" 
              v-for="category in categories" >
              <div class="flex items-center">
                <!-- <div class="h-8 w-8 text-center group-data-[theme-color=violet]:bg-violet-500/20 group-data-[theme-color=sky]:bg-sky-500/20 group-data-[theme-color=red]:bg-red-500/20 group-data-[theme-color=green]:bg-green-500/20 group-data-[theme-color=pink]:bg-pink-500/20 group-data-[theme-color=blue]:bg-blue-500/20 leading-[2.4] rounded group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 text-sm font-medium">
                          {{jobListingCategory.JobListingCount}}
                      </div> -->
                      <a href="javascript:void(0)" 
                      class="text-gray-900 dark:text-gray-50" 
                      style = "cursor:pointer;"
                      v-on:click="handleCategoryClick(category)" >
                          <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                            {{ category }}
                          </h6>
                      </a>
                  </div>
            </li>
          </ul>
            <!-- Page: FeedbackNotification -->
            <FeedbackNotification style="margin-bottom: 15px;" 
              v-if="this.notification.text != ''" 
              :feedbackText="this.notification.text" 
              :feedbackLevel="this.notification.level"/>

              <div style="padding-top: 30px;" class="col-span-12 lg:col-span-8">
                    <div class="mb-3 mb-lg-0">
                        <h6 class="text-gray-900 text-16 dark:text-gray-50"> Showing {{ this.articleShowingCount }} of {{ this.articleResultCount }} Articles </h6>
                    </div>
                </div><!--end col-->
      <div class="grid grid-cols-12 md:gap-12md:gap-y-0">
          <div class="col-span-12 lg:col-span-12">
              <!-- Articles: Results -->
              <div v-if="articlesLoading">
                  <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom:800px"/>
                </div>
                <div v-else class="grid grid-cols-12 md:gap-5">
                  <div v-bind:key="article" v-for="article in articles" 
                  class="col-span-12 lg:col-span-3">
                      <div class="p-2 transition-all duration-500 bg-white rounded-md shadow-md hover:-translate-y-2 dark:bg-transparent dark:shadow-none">
                          <img :src=article.MainImage @click="handleArtlicleClick(article.Slug)" style="height: 200px; cursor: pointer;" alt="" class="img-fluid">
                          <div class="p-5" style="height: 240px;">
                              <ul class="flex mb-3 list-inline">
                                <li v-bind:key="category" v-for="category in article.Categories" >
                                  <span 
                                  class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                                  style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;" 
                                    :class="
                                    { 
                                      'bg-green-500/20': search.tags.includes(category),  
                                      '': !search.tags.includes(category) 
                                    }" 
                                    v-on:click="handleCategoryClick(category)">
                                      <a>{{ category }}</a>
                                    </span>
                                </li>
                              </ul>
                              <a class="primary-link">
                                <h6 
                                style="cursor: pointer"
                                @click="handleArtlicleClick(article.Slug)" 
                                class="text-gray-900 transition-all duration-300 text-17 dark:text-white group-data-[theme-color=violet]:hover:text-violet-500 group-data-[theme-color=sky]:hover:text-sky-500 group-data-[theme-color=red]:hover:text-red-500 group-data-[theme-color=green]:hover:text-green-500 group-data-[theme-color=pink]:hover:text-pink-500 group-data-[theme-color=blue]:hover:text-blue-500">{{ article.Title }}</h6></a>
                              <p class="mt-2 text-gray-500 dark:text-gray-300">{{ article.Excerpt }}.</p>
                              <!-- <div class="mt-4 font-medium group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500">
                                  <a href="#" @click="handleArtlicleClick(article.Slug)" class="form-text ">Read More <i class="uil uil-angle-right-b"></i></a>
                              </div> -->
                            </div>
                      </div>
                  </div>
                <!-- <Pagination
                    :totalPages="Math.ceil(this.articleResultCount / pageSize)"
                    :perPage="pageSize"
                    :currentPage="currentPage"
                    @pagechanged="onPageChange" />-->
                </div> 
              </div>
            </div>
        </div>
        </div>
                        
</section>
 
</div>
</template>

<script>

import { contentAPI } from "@/api/content";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "Articles",
  components : {
    FeedbackNotification
  }, 
  data() {
    return {
      search : {
        tags: this.$route.query.tags !== undefined ? this.$route.query.tags : [],
      },
      pageLoading : true,
      articlesLoading : true,
      categories : [],
      articles : null,      
      articleResultCount : 0,
      articleShowingCount : 0,
      notification : {
        text : "",
        level : "",
      }
    };
  },
  created(){
    this.getCategories();
    this.searchArticles();
  },
  mounted() {
    document.title = 'Articles - TheJobTap.com'
  },
  methods: {
  async handleArtlicleClick(articleName){
    this.$router.push('/articles/' + articleName + "/");
  },
  async handleCategoryClick(categoryName) {
      if (categoryName == null)
      {
         this.search.tags = [];
         await this.searchArticles();
         return;
      }
      if(!Array.isArray(this.search.tags))
      {
         this.search.tags = [categoryName];
         await this.searchArticles();
         return;
      }
      const categoryNames = [];
      this.search.tags.forEach(element => {
        if (element != categoryName)
        {
          categoryNames.push(element);
        }
      });
      if (!this.search.tags.includes(categoryName))
      {
        categoryNames.push(categoryName);
      }
      this.search.tags = categoryNames;
      await this.searchArticles();
    },
    async createQueryString() {
      const query = Object.assign({}, this.$route.query);
      query.tags = this.search.tags;
      await this.$router.push({ query });
    },
  async getCategories() {
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getCategories();
          this.categories = result;
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
  },
  async searchArticles() {
    this.articlesLoading = true;
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.searchArticles(this.search.tags);
          this.articles = result.Results;
          this.articleResultCount = result.ResultCount;
          this.articleShowingCount = result.Results.length; 
          await this.createQueryString();
        })
      );
      this.notification = { text : "", level : "" };
      this.articlesLoading = false;
      if (!result.success) 
      {
        this.articles = null;
        this.articleResultCount = 0;
        this.articleShowingCount = 0;
        this.notification = { text : result.data, level : "error" };        
      }
  },
}
};
</script>